var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5 mt-5"},[_c('div',{staticClass:"text-center"},[_c('h1',{staticClass:"display-4"},[_vm._v(_vm._s(_vm.title))]),_c('p',{staticClass:"lead"},[_vm._v(_vm._s(_vm.description))])]),_c('div',{staticStyle:{"width":"100%","margin":"20px auto","height":"400px"}},[_c('slider',{ref:"slider",attrs:{"options":_vm.options},on:{"slide":_vm.slide,"tap":_vm.onTap,"init":_vm.onInit}},[_vm._l((_vm.images),function(image,index){return _c('slideritem',{key:index,style:({
          width: '100%',
          height: '100%',
          'background-image': ("url(" + (_vm.url + image.url) + ")"),
          'background-size': 'cover',
          'max-width': '250px',
          'margin-left': '10px',
          'margin-right': '10px',
          'background-position': 'center',
        })})}),_c('div',{attrs:{"slot":"loading"},slot:"loading"},[_vm._v("loading...")])],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }