<template>
  <div class="px-5 my-5">
    <h3 class="text-center">Contáctanos</h3>
    <b-card>
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col cols="6">
            <b-form-group label="Nombre" label-for="name-input">
              <b-form-input
                id="name-input"
                v-model="lead.name"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Apellido" label-for="name-input">
              <b-form-input
                id="name-input"
                v-model="lead.lastname"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Correo" label-for="email-input">
              <b-form-input
                id="email-input"
                v-model="lead.email"
                type="email"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Celular" label-for="phone-input">
              <b-form-input
                id="phone-input"
                v-model="lead.phone"
                type="text"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Message" label-for="message-input">
              <b-form-textarea
                id="message-input"
                v-model="lead.message"
                required
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-button type="submit" variant="primary">Submit</b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lead: {
        name: "",
        lastname: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    submitForm() {
      console.log(this.lead);
    },
  },
};
</script>

<style>
</style>