<template>
  <div class="p-5">
    <div class="divider"></div>
  </div>
</template>


<script>
export default {
  name: "Divider",
};
</script>
<style >
.divider {
  height: 1px;
  width: 100%;
  margin: 10px 0;
    background-color: #e0e0e0;

}
</style>