<template>
  <div>
    <Navbar />

    <section id="rooms">
      <Carousel
        :title="rooms.title"
        :description="rooms.description"
        :images="images"
      />
    </section>

    <Divider />
    <section id="promotions">
      <Carousel
        :title="promotions.title"
        :description="promotions.description"
        :images="images"
      />
    </section>

    <section id="contactus">
      <ContactForm />
    </section>

    <section id="map">
      <Map />
    </section>
    <Footer />
    <router-view />
  </div>
</template>
<script>
import Navbar from "@/views/main/views/landing/components/Navbar.vue";
import Carousel from "@/views/main/views/landing/components/Carousel.vue";
import Divider from "@/views/main/views/landing/components/Divider.vue";
import Map from "@/views/charts-and-maps/maps/leaflet/Leaflet.vue";
import Footer from "@/views/main/views/landing/components/Footer.vue";
import ContactForm from "@/views/main/views/landing/components/ContactForm.vue";
export default {
  name: "Landing",
  components: {
    Navbar,
    Carousel,
    Divider,
    Map,
    Footer,
    ContactForm,
  },
  data() {
    return {
      rooms: {
        title: "Habitaciones",
        description: "El mejor hotel para pasar un buen rato con tu cachera",
      },
      promotions: {
        title: "Promociones",
        description: "Las mejores promociones para que te quedes con nosotros",
      },
      images: [
        {
          url: "/400/400/?image=41",
        },
        {
          url: "/400/400/?image=42",
        },
        {
          url: "/400/400/?image=43",
        },
        {
          url: "/400/400/?image=44",
        },
        {
          url: "/400/400/?image=45",
        },
        {
          url: "/400/400/?image=46",
        },
        {
          url: "/400/400/?image=47",
        },
        {
          url: "/400/400/?image=48",
        },
        {
          url: "/400/400/?image=41",
        },
        {
          url: "/400/400/?image=42",
        },
        {
          url: "/400/400/?image=43",
        },
        {
          url: "/400/400/?image=44",
        },
        {
          url: "/400/400/?image=45",
        },
        {
          url: "/400/400/?image=46",
        },
        {
          url: "/400/400/?image=47",
        },
        {
          url: "/400/400/?image=48",
        },
      ],
    };
  },
};
</script>
<style >
/* Add the following styles to your CSS file */

.animate {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.3s ease-in-out;
}

.animate-show {
  opacity: 1;
  transform: translateY(0);
}

/* global smoth scroll  */
html {
  scroll-behavior: smooth;
}
</style>